import { useState } from 'react';
export const ascendium = '/supporters/ascendium.png';
export const blueRidge = '/supporters/blueRidge.png';
export const camelback = '/supporters/camelback.png';
export const mellon = '/supporters/mellon.png';
export const milken = '/supporters/milken.png';
export const MITSolve = '/supporters/MITSolve.png';
export const newProfit = '/supporters/newProfit.png';
export const pluralsight = '/supporters/pluralsight.png';
export const roddenberry = '/supporters/roddenberry.png';
export const schmidt = '/supporters/schmidt.png';
export const dreamorg = '/supporters/dreamorg.png';
export const fastforward = '/supporters/fastforward.png';
export const impactAccelerator = '/supporters/impactAccelerator.png';
export const kaplan = '/supporters/kaplan.png';
export const turso = '/supporters/turso.png';

const imagesArray = [
    ascendium,
    blueRidge,
    camelback,
    mellon,
    milken,
    MITSolve,
    newProfit,
    pluralsight,
    roddenberry,
    schmidt,
    dreamorg,
    fastforward,
    impactAccelerator,
    kaplan,
    turso
];

let intervalID: any = null;

export function SupportersCarousel() {
    let [currSlide, setCurrSlide] = useState(0);

    if (intervalID) {
        clearInterval(intervalID);
    }
    intervalID = setInterval(shiftCarouselNext, 4000);

    function stopInterval() {
        clearInterval(intervalID);
    }

    function shiftCarouselNext() {
        if (currSlide == 2) setCurrSlide(0);
        else setCurrSlide(currSlide + 1);
    }

    return (
        <div className="mx-auto py-5 md:p-10">
            <div className="flex flex-row gap-5 text-center justify-center">
                <div className="text-UL-green my-auto text-center text-base md:text-3xl">
                    {' '}
                    Our work is supported by:{' '}
                </div>
            </div>
            <div
                className="py-5 md:py-20 relative flex items-center"
                onClick={stopInterval}
            >
                <div className="w-[345px] md:w-[810px] h-full overflow-x-scroll whitespace-nowrap scroll-smooth mx-auto hide-scrollbar">
                    <div
                        className={`flex transition ease-out duration-700 gap-1 md:gap-3`}
                        style={{
                            transform: `translateX(-${currSlide * 100}%)`
                        }}
                    >
                        {imagesArray.map((logo, index) => {
                            return (
                                <img
                                    key={index}
                                    className="w-[65px] h-[65px] rounded-[20px] md:w-[150px] md:h-[150px] md:rounded-[50px] bg-zinc-600  p-2 object-scale-down object-center"
                                    src={logo}
                                    alt={'logo'}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
