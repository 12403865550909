import { Link } from 'react-router-dom';
import { Button } from './Button';

export function Footer() {
    return (
        <footer className="pb-[5%] px-5 xl:pt-[50px] xl:px-[100px]">
            <div className="flex flex-col justify-center gap-10 py-5 mx-auto lg:flex-row xl:gap-2 xl:w-[95%]">
                <Link className="md:w-1/4 xl:w-[15%]" to="/">
                    <img
                        className="flex-auto w-1/3 sm:max-xl:w-full xl:w-[90%]"
                        src={'/ul-logo.png'}
                        alt="Unlocked Labs Logo"
                    />
                </Link>
                <div className="flex flex-col justify-center gap-5 lg:flex-row lg:gap-5 lg:text-left lg:px-0">
                    <div className="flex flex-col gap-2">
                        <h2 className="text-UL-green text-base xl:text-xl">
                            About Us
                        </h2>
                        <Link className="text-white text-sm" to="/team">
                            Our Team
                        </Link>
                        <Link className="text-white text-sm" to="/mission">
                            Our Mission
                        </Link>
                        <Link className="text-white text-sm" to="/story">
                            Our Story
                        </Link>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-UL-green text-base xl:text-xl">
                            Services
                        </h2>
                        <Link className="text-white text-sm" to="/unlocked">
                            UnlockEd
                        </Link>
                        <Link
                            className="text-white text-sm"
                            to="/coding-education"
                        >
                            Coding Education
                        </Link>
                        <Link
                            className="text-white text-sm"
                            to="/tech-consulting"
                        >
                            Tech Consulting
                        </Link>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-UL-green text-base xl:text-xl">
                            Learn More
                        </h2>
                        <Link className="text-white text-sm" to="/press">
                            Press
                        </Link>
                        <Link className="text-white text-sm" to="/careers">
                            Careers
                        </Link>
                        <Link
                            className="text-white text-sm"
                            to="https://www.linkedin.com/company/labs-unlocked/"
                        >
                            LinkedIn
                        </Link>
                    </div>
                </div>
                <div className="hidden lg:block w-0 border border-UL-green mx-4"></div>
                <div className="flex flex-col align-middle my-auto md:max-lg:w-1/3 md:mx-auto xl:w-[40%]">
                    <p className="hidden text-white text-center text-sm font-light mx-auto pb-[2%] lg:block lg:w-[90%]">
                        Feel inspired by our mission? Become a part of our
                        community as we build a better justice system,
                        <span className="text-UL-green">
                            {' '}
                            from the inside, out.
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row gap-2 justify-center">
                        <Button title={'Connect With Us'} href={'./contact'} />
                        <Button
                            title={'Sign up for our newsletter'}
                            href={
                                'https://share.hsforms.com/1fB068cL8TXGYzeG0Je4Cfwnuoem'
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="text-white text-xs text-center font-extralight">
                Copyright 2024 by Unlocked Labs. All rights reserved. EIN
                #37-1871086
            </div>
        </footer>
    );
}
