export const stripeUrl =
    'https://app.hubspot.com/payments/purchase/hscs_J1uqCFDTG6xYhA1fFs1pPSuH78r4cQ1vneJehiWTYQGRX7rJ3qDeM8OX4gj2nmWL?referrer=PAYMENT_LINK';

export interface IJob {
    title: string;
    id: string;
    category: string;
    location: string;
    overview: string;
    responsibilities: Array<string>;
    qualifications: Array<string>;
    [key: string]: string | Array<string>;
}

export interface Press {
    id: string;
    publish_date: string;
    title: string;
    publisher: string;
    link: string;
}

export interface ServerResponse<T> {
    data: T[];
    total?: number;
    last_page?: number;
}

export interface Team {
    id: string;
    photo: string;
    name: string;
    position: string;
}

export interface AppForm {
    first_name: string;
    last_name: string;
    email: string;
    message: string;
    resume: File | null;
    cover_letter?: File | null;
}
