import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';

interface TeamMember {
    id: string;
    name: string;
    position: string;
    photo: string;
}

interface Photo {
    image_preview: string;
    image_file: File;
}

export default function EditTeamMember() {
    const [photo, setPhoto] = useState<Photo>();
    const [teamMember, setTeamMember] = useState<TeamMember>({
        id: '',
        name: '',
        position: '',
        photo: ''
    });
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const fetchTeamMember = async () => {
            try {
                const resp = await fetch(`/api/team/${id}`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (resp.ok) {
                    const data = await resp.json();
                    setTeamMember({
                        id: data.id,
                        name: data.name,
                        position: data.position,
                        photo: data.photo
                    });
                } else {
                    alert('Error fetching team member data');
                    navigate('/team', { replace: true });
                }
            } catch {
                alert('Error fetching team member data');
                navigate('/team', { replace: true });
            }
        };

        if (id) {
            void fetchTeamMember();
        }
    }, [id, navigate]);

    const handleSubmit = async () => {
        const formData = new FormData();
        const name = teamMember.name.split(' ')[0];
        formData.append('name', teamMember.name);
        formData.append('position', teamMember.position);
        if (photo && photo.image_file) {
            formData.append('photo', photo.image_file, `${name}.png`);
        }
        try {
            const resp = await fetch(`/api/team/${teamMember.id}`, {
                method: 'PUT', // Use PUT or PATCH depending on your API
                credentials: 'include',
                body: formData
            });
            if (resp.ok) {
                navigate('/team', { replace: true });
            } else {
                alert('Error updating team member');
            }
        } catch {
            alert('Error updating team member');
        }
    };

    const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (files && files.length > 0) {
            const image_file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhoto({
                    image_preview: reader.result as string,
                    image_file: image_file
                });
            };
            reader.readAsDataURL(image_file);
        }
    };

    const handleChange = (name: keyof TeamMember, val: string) => {
        setTeamMember((prevTeamMember) => ({
            ...prevTeamMember,
            [name]: val
        }));
    };

    return (
        <div className="bg-black min-h-screen">
            <Header title="Edit Team Member" />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    void handleSubmit();
                }}
                className="w-[80%] mx-auto py-10 text-white"
            >
                {photo && photo.image_preview ? (
                    <img
                        className="rounded-[30px] w-64 h-64"
                        src={photo.image_preview}
                        alt="preview"
                    />
                ) : (
                    <img
                        src={`/api/photos?name=${teamMember.photo}`}
                        alt="Current Photo"
                    />
                )}

                <div className="mb-6">
                    <label
                        htmlFor="name"
                        className="block text-xl font-semibold"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={teamMember.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="position"
                        className="block text-xl font-semibold"
                    >
                        Position
                    </label>
                    <input
                        type="text"
                        id="position"
                        name="position"
                        value={teamMember.position}
                        onChange={(e) =>
                            handleChange('position', e.target.value)
                        }
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="photo"
                        className="block text-xl font-semibold"
                    >
                        Update Photo (optional)
                    </label>
                    <input
                        type="file"
                        id="photo"
                        name="photo"
                        onChange={handleImagePreview}
                        className="w-full p-3 mt-2 bg-base-gray text-white rounded"
                    />
                </div>
                <div className="flex justify-between mt-10">
                    <button
                        type="submit"
                        className="px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-xl font-normal"
                    >
                        Save Changes
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/team', { replace: true })}
                        className="px-5 py-2 bg-gray-500 text-white rounded-lg text-center text-xl font-normal"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
