import { useState, useRef, FormEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export function ContactForm({
    handleSubmit,
    submitting
}: {
    handleSubmit: any;
    submitting: boolean;
}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [involvement, setInvolvement] = useState('');
    const [message, setMessage] = useState('');
    const recaptcha = useRef<ReCAPTCHA>(null);
    const [captchaValue, setCaptchaValue] = useState('');

    function onReCAPTCHAChange(value: string | null) {
        if (value) {
            setCaptchaValue(value);
        } else {
            setCaptchaValue('');
        }
    }

    async function validateCaptcha(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!');
        } else {
            const res = await fetch('/api/recaptcha', {
                method: 'POST',
                body: JSON.stringify({ captchaValue }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await res.json();
            if (data.success) {
                sendEmail();
                console.log('Success!');
            } else {
                alert('reCAPTCHA validation failed!');
                if (recaptcha.current) {
                    recaptcha.current.reset();
                }
            }
        }
    }

    function sendEmail() {
        let formData = new FormData();
        formData.append('name', name);
        formData.append('from', email);
        formData.append('company', company);
        formData.append('involvement', involvement);
        formData.append('message', message);
        formData.append(
            'subject',
            'Website Contact Form Submission from ' + name
        );
        handleSubmit(formData);
    }
    return (
        <form
            onSubmit={(e) => validateCaptcha(e)}
            className="
        flex flex-col justify-center font-extralight text-white
        "
        >
            <div className="flex flex-col justify-center gap-2 xl:grid xl:grid-cols-2 pb-2">
                <input
                    className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl
                md:border-[1px] md:text-2xl lg:max-xl:text-4xl md:max-xl:p-5
                "
                    required
                    placeholder="Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl
                md:border-[1px] md:text-2xl lg:max-xl:text-4xl md:max-xl:p-5
                xl:w-full
                "
                    required
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl
                md:border-[1px] md:text-2xl lg:max-xl:text-4xl md:max-xl:p-5
                xl:w-full
                "
                    placeholder="Company"
                    type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />
                <input
                    className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl
                md:border-[1px] md:text-2xl lg:max-xl:text-4xl md:max-xl:p-5
                xl:w-full
                "
                    required
                    placeholder="How would you like to get involved?"
                    type="text"
                    value={involvement}
                    onChange={(e) => setInvolvement(e.target.value)}
                />
            </div>
            <textarea
                className="border-[0.5px] border-white bg-transparent pl-3 py-3 text-xl h-40 w-full mx-auto
            placeholder:mt-0
            md:border-[1px] md:text-2xl lg:max-xl:text-4xl
            xl:w-full
            "
                required
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <ReCAPTCHA
                ref={recaptcha}
                sitekey="6LflNnMpAAAAAI8H7-mXg694kifXQZkFbe5fOIFX"
                onChange={onReCAPTCHAChange}
                className="mx-auto p-4"
            />
            <div className="mx-auto">
                <button
                    type="submit"
                    className="border-[0.5px] border-base-gray bg-UL-green py-2 px-16 text-xl text-base-gray font-semibold md:border-[1px] md:max-xl:text-4xl md:px-30 xl:px-40"
                    disabled={submitting}
                >
                    {submitting ? 'Submitting...' : 'Submit'}
                </button>
            </div>
        </form>
    );
}
