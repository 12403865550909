import { useState, useContext, FormEvent, useRef } from 'react';
import { AuthContext } from '../components/authCtx';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export const LoginForm = () => {
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const recaptcha = useRef<ReCAPTCHA>(null);
    const [captchaValid, setCaptchaValid] = useState(false);
    const [loading, setLoading] = useState(false);

    function onReCAPTCHAChange(value: string | null) {
        setCaptchaValid(!!value);
    }

    const handleSubmitLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!captchaValid) {
            alert('Please complete the reCAPTCHA validation.');
            return;
        }
        setLoading(true);
        try {
            const resp = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email, password: password })
            });
            const data = await resp.json();

            if (resp.ok && data.success) {
                setIsAuthenticated(true);
                navigate('/');
            } else {
                alert(data.message || 'Invalid login credentials');
                if (recaptcha.current) {
                    recaptcha.current.reset();
                }
                setCaptchaValid(false);
            }
        } catch (error) {
            alert('Error logging in. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = async () => {
        try {
            const resp = await fetch('/api/logout', {
                method: 'POST',
                credentials: 'include'
            });
            if (resp.ok) {
                setIsAuthenticated(false);
                navigate('/');
            }
        } catch {
            alert('Error logging out');
            navigate('/');
        }
    };

    return (
        <div className="bg-black min-h-screen flex items-center justify-center">
            {!isAuthenticated ? (
                <form
                    onSubmit={handleSubmitLogin}
                    className="bg-base-gray p-8 rounded-lg shadow-lg w-120"
                >
                    <h2 className="text-2xl text-UL-green text-center mb-6">
                        Staff Login
                    </h2>
                    <div className="mb-4">
                        <label
                            className="block text-white text-sm mb-2"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <input
                            className="w-full p-2 rounded bg-white text-black"
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label
                            className="block text-white text-sm mb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            className="w-full p-2 rounded bg-white text-black"
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <ReCAPTCHA
                        ref={recaptcha}
                        sitekey="6LflNnMpAAAAAI8H7-mXg694kifXQZkFbe5fOIFX"
                        onChange={onReCAPTCHAChange}
                        className="mx-auto p-4"
                    />
                    <button
                        type="submit"
                        className={`w-full self-center border-black bg-UL-green text-base-gray py-2 rounded hover:bg-UL-green ${loading ? 'opacity-50' : ''}`}
                        disabled={!captchaValid || loading}
                    >
                        {loading ? 'Logging in...' : 'Log In'}
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/')}
                        className="w-full bg-base-gray border-white text-UL-green py-2 rounded hover:bg-UL-green hover:text-black hover:border-black"
                    >
                        Return Home
                    </button>
                </form>
            ) : (
                <div>
                    <h2 className="text-base-gray text-xl">
                        Currently Logged in...
                    </h2>
                    <button
                        className="w-72 border-black bg-UL-green text-base-gray py-2 rounded hover:bg-UL-green"
                        onClick={handleLogout}
                    >
                        Logout
                    </button>
                </div>
            )}
        </div>
    );
};
