import Home from './app/page.tsx';
import './app/globals.css';
import './App.css';
import ReactGA from 'react-ga4';
import {
    createBrowserRouter,
    Navigate,
    Outlet,
    RouterProvider
} from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import Mission from './app/mission/page';
import OurStory from './app/story/page';
import UnlockEd from './app/unlocked/page';
import CodingEducation from './app/coding-education/page';
import TechConsulting from './app/tech-consulting/page';
import PressPage from './app/press/page';
import Careers from './app/careers/page';
import Contact from './app/contact/page';
import Loading from './components/loading';
import JobInfo from './app/careers/viewJob';
import { AuthContext, AuthProvider } from './components/authCtx.tsx';
import Error from './components/error.tsx';
import { LoginForm } from './components/LoginForm.tsx';
import EditCareerForm from './components/editCareerForm.tsx';
import { Suspense, useContext } from 'react';
import AddCareerForm from './components/addCareerForm.tsx';
import AddPressForm from './components/addPressForm.tsx';
import AddTeamMember from './app/team/addTeamMember.tsx';
import AboutUs from './app/team/page.tsx';
import EditTeamMember from './app/team/editTeamMember.tsx';

const Parent = () => {
    return (
        <AuthProvider>
            <div className="bg-black">
                <Navbar />
                <Outlet />
                <Footer />
            </div>
        </AuthProvider>
    );
};
const ProtectedRoute = () => {
    const { isAuthenticated } = useContext(AuthContext);
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }
    return <Outlet />;
};
const router = createBrowserRouter([
    {
        path: '/',
        element: <Parent />,
        errorElement: <Error />,
        children: [
            {
                path: '',
                element: <Home />
            },
            {
                path: 'login',
                element: <LoginForm />
            },
            {
                path: 'team',
                element: <AboutUs />
            },
            {
                path: 'mission',
                element: <Mission />
            },
            {
                path: 'story',
                element: <OurStory />
            },
            {
                path: 'unlocked',
                element: <UnlockEd />
            },
            {
                path: 'coding-education',
                element: <CodingEducation />
            },
            {
                path: 'tech-consulting',
                element: <TechConsulting />
            },
            {
                path: 'press',
                element: <PressPage />
            },
            {
                path: 'careers',
                element: <Careers />
            },
            {
                path: 'careers/:id',
                element: <JobInfo />
            },
            {
                path: 'contact',
                element: <Contact />
            },
            {
                path: '',
                element: <ProtectedRoute />,
                children: [
                    {
                        path: 'careers/:id/edit',
                        element: <EditCareerForm />
                    },
                    {
                        path: 'careers/new',
                        element: <AddCareerForm />
                    },
                    {
                        path: 'press/new',
                        element: <AddPressForm />
                    },
                    {
                        path: 'team/new',
                        element: <AddTeamMember />
                    },
                    {
                        path: 'team/:id/edit',
                        element: <EditTeamMember />
                    }
                ]
            }
        ]
    }
]);

export default function App() {
    ReactGA.initialize('G-5BXKZM51WJ');
    ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search
    });
    if (import.meta.hot) {
        import.meta.hot.dispose(() => router.dispose());
    }

    return (
        <Suspense fallback={<Loading />}>
            <RouterProvider router={router} fallbackElement={<Loading />} />
        </Suspense>
    );
}
